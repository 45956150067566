import {Box, Button, Checkbox, FormControlLabel, FormGroup, FormHelperText, TextField} from "@mui/material";
import React from "react";
import {useForm} from "react-hook-form";
import {cronDescription} from "../utils/cron";

export type SchedulerJobFormValue = {
  cron: string;
  args: string;
  name: string;
  historyLimit: number;
  exclusiveRun?: boolean;
}

type Props = {
  initialValue?: SchedulerJobFormValue;
  onSubmit: (data: SchedulerJobFormValue) => void;
}

export const SchedulerJobForm: React.FC<Props> = ({ onSubmit, initialValue }) => {
  const { handleSubmit, register, watch, formState } = useForm<SchedulerJobFormValue>({
    defaultValues: {
      historyLimit: 50,
      exclusiveRun: true,
      ...initialValue
    },
    mode: 'onChange'
  });

  const cron = watch('cron');

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{ mt: 2 }}>
        <TextField
          label="Name"
          required
          variant="outlined"
          size="small"
          fullWidth
          autoComplete="off"
          {...register('name', { required: true })}
          sx={{ mb: 3 }}
        />

        <TextField
          label="Cron"
          required
          variant="outlined"
          size="small"
          fullWidth
          helperText={cronDescription(cron)}
          autoComplete="off"
          {...register('cron', { required: true })}
          sx={{ mb: 3 }}
        />

        <TextField
          label="Args"
          required
          variant="outlined"
          size="small"
          fullWidth
          autoComplete="off"
          {...register('args', { required: true })}
          sx={{ mb: 3 }}
        />

        <TextField
          label="History limit"
          type="number"
          required
          variant="outlined"
          size="small"
          fullWidth
          autoComplete="off"
          {...register('historyLimit', {
            required: true,
            valueAsNumber: true,
            max: {
              value: 50,
              message: 'Max value is 50'
            },
            min: {
              value: 1,
              message: 'Min value is 1'
            }
          })}
          sx={{ mb: 2 }}
          error={!!formState.errors.historyLimit}
          helperText={formState.errors.historyLimit?.message}
        />

        <FormGroup sx={{ mb: 3 }}>
          <FormControlLabel control={<Checkbox {...register('exclusiveRun')} />} label="Exclusive run" />
          <FormHelperText>This job will not be scheduled until previous run finishes when checked.</FormHelperText>

          {!watch('exclusiveRun') && (
            <FormHelperText sx={{ color: 'warning.light' }}>If you are not going to use exclusive run, then be sure that you are locking correctly in the implementation</FormHelperText>
          )}
        </FormGroup>
      </Box>

      <Button type="submit" variant="contained">{initialValue ? 'Update' : 'Create'}</Button>
    </form>
  );
};
